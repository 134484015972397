import React from 'react';
import { graphql } from "gatsby";
import styled from 'styled-components'

import Container from '../components/layout/Container';
import DefaultLayout from '../layout/defaultLayout';
import download from '../images/download.svg';

const StyledDocs = styled.div`
#files {
  .file {
    margin: 10px 0;
    img.downloadIcon {
      width: 20px;
      margin: 0px 10px;
    }
    h4 {
      margin: 0;
      display: inline-block;
    }
  }
}
`

const Documenten = ({ data }) => (
  <DefaultLayout>
    <Container>
      <StyledDocs>
        <h1>Documenten</h1>
        <div id="files">
          {data.allContentfulDocument.edges.map(doc => {
            const { contentful_id, omschrijving, document } = doc.node;
            return (
              <div className="file" key={contentful_id}>
                <h4>{omschrijving}</h4>
                <a href={document.file.url} download>
                  <img src={download} className="downloadIcon" alt="download icon" />
                </a>
              </div>
            )
          })}
        </div>
      </StyledDocs>
    </Container>
  </DefaultLayout>
)

export default Documenten

export const documentQuery = graphql`
  query {
      allContentfulDocument {
        edges {
          node {
            omschrijving
            document {
              file {
                url
                contentType
              }
              title
            }
            contentful_id
          }
        }
      }
    }
`
